import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Avatar } from "../../../components/avatar.jsx";
import { Flex, Box } from "@theme-ui/components";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Flex sx={{
      flexWrap: 'wrap'
    }} mdxType="Flex">
    <Box mt={23} mb={20} mr={40} sx={{
        width: [150, 205, 205]
      }} mdxType="Box">
        <Avatar mdxType="Avatar" />
    </Box>
    <Box sx={{
        width: ['100%', '55%', '65%']
      }} mdxType="Box">
        <Text sx={{
          fontSize: [4, 5, 6],
          fontWeight: `bold`,
          color: `heading`,
          display: `block`
        }} mdxType="Text">
            Hi.
        </Text>
        <Text sx={{
          fontSize: [1, 2, 3]
        }} mdxType="Text">
            I'm Brendan - currently living and breathing in San Diego, California.
            I'm a Full Stack Software Developer at Intuit.
            I'm working on the chat platform used in TurboTax and Quickbooks.
            I also worked on the rollout of TurboTax Full Service.
        </Text>
    </Box>
    </Flex>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      